<template>
  <div class="dashboard">
    <b-container class="shlajsna">
      <b-row>
        <b-col cols="12">
          <h1 class="text-center">{{ $t('tags.add-tag') }}</h1>
        </b-col>
        <b-col cols="12">
          <NavBar></NavBar>
        </b-col>
        <b-col cols="12" class="px-4">
          <TagForm :tag="{}" />
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import NavBar from "@/components/common/NavBar.vue"
import TagForm from '@/components/admin/tag/TagForm.vue'

export default {
  name: 'AddTag',
  components: { NavBar, TagForm }
}
</script>
<style></style>
